import React from 'react';
import { Modal } from 'antd';
import './TermsOfService.css';

interface TermsOfServiceProps {
  isVisible: boolean;
  onClose: () => void;
}

const TermsOfService: React.FC<TermsOfServiceProps> = ({ isVisible, onClose }) => {
  return (
    <Modal
      title="Terms of Service"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={700}
    >
      <div className="terms-content">
        <h2>Pickup Service Terms and Conditions</h2>
        
        <section>
          <h3>1. Service Overview</h3>
          <p>We provide a pickup service for items you wish to dispose of or donate. By using our service, you agree to these terms.</p>
        </section>

        <section>
          <h3>2. User Responsibilities</h3>
          <ul>
            <li>You must be the legal owner of the items or have permission to dispose of them</li>
            <li>Items must be accessible at the provided address during the scheduled pickup time</li>
            <li>You must provide accurate information about the items and their condition</li>
            <li>Items must be safe to handle and free from hazardous materials</li>
          </ul>
        </section>

        <section>
          <h3>3. Service Limitations</h3>
          <ul>
            <li>We reserve the right to refuse pickup of items that don't match their description</li>
            <li>Certain items may not be eligible for pickup (hazardous materials, illegal items)</li>
            <li>Service availability may vary by location and time</li>
          </ul>
        </section>

        <section>
          <h3>4. Scheduling and Cancellation</h3>
          <ul>
            <li>You must be present or make arrangements for the pickup</li>
            <li>Cancellations must be made at least 24 hours before scheduled pickup</li>
            <li>We may reschedule pickups due to weather or unforeseen circumstances</li>
          </ul>
        </section>

        <section>
          <h3>5. Privacy and Data</h3>
          <ul>
            <li>We collect and store your contact information and pickup details</li>
            <li>Photos of items are stored securely and used only for service purposes</li>
            <li>We don't share your information with third parties except as required for service delivery</li>
          </ul>
        </section>

        <section>
          <h3>6. Liability</h3>
          <p>We are not responsible for:</p>
          <ul>
            <li>Items mistakenly disposed of or donated</li>
            <li>Damage to property during pickup unless caused by gross negligence</li>
            <li>Items left outside or in unsecured locations</li>
          </ul>
        </section>
      </div>
    </Modal>
  );
};

export default TermsOfService; 