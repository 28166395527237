import React from 'react';
import { CheckOutlined } from '@ant-design/icons';

interface ProgressProps {
  steps: string[];
  currentStep: number;
}

export const Progress = ({ steps, currentStep }: ProgressProps) => {
  return (
    <div className="progress-container">
      <div className="progress-bar">
        <div className="progress-line-background"></div>
        <div 
          className="progress-line-fill"
          style={{ width: `${((currentStep - 1) / (steps.length - 1)) * 100}%` }}
        ></div>
        <div className="progress-steps">
          {steps.map((step, index) => (
            <div key={index} className="progress-step">
              <div className={`progress-circle ${index < currentStep ? 'completed' : ''}`}>
                <div className="circle-content">
                  {index < currentStep ? <CheckOutlined /> : index + 1}
                </div>
              </div>
              <span className="progress-step-title">{step}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
