import React, { useState } from 'react';
import { Form, Input, Button, Rate, Radio, message } from 'antd';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import './FeedbackForm.css';

const { TextArea } = Input;

interface FeedbackFormProps {
  onClose: () => void;
}

const removeUndefinedFields = (obj: Record<string, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined));
};

const FeedbackForm: React.FC<FeedbackFormProps> = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      // Prepare data and filter out undefined fields
      const feedbackData = {
        ...values,
        improvements: values.improvements || undefined,
        timestamp: new Date(),
      };
      const filteredData = removeUndefinedFields(feedbackData);

      // Submit to Firestore
      await addDoc(collection(db, 'feedback'), filteredData);
      message.success('Thank you for your feedback!');
      onClose();
    } catch (error) {
      console.error('Error submitting feedback:', error);
      message.error('Failed to submit feedback. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="feedback-form-container">
      <h2>Alpha Testing Feedback</h2>
      <p className="feedback-subtitle">Help us improve by sharing your experience</p>
      <Form
        layout="vertical"
        onFinish={onFinish}
        className="feedback-form"
      >
        <Form.Item
          name="usability"
          label="How easy was it to use the application?"
          rules={[{ required: true, message: 'Please rate the usability' }]}
        >
          <Rate />
        </Form.Item>
        <Form.Item
          name="experience"
          label="How would you rate your overall experience?"
          rules={[{ required: true, message: 'Please select your experience' }]}
        >
          <Radio.Group>
            <Radio.Button value="poor">Poor</Radio.Button>
            <Radio.Button value="fair">Fair</Radio.Button>
            <Radio.Button value="good">Good</Radio.Button>
            <Radio.Button value="excellent">Excellent</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="issues"
          label="Did you encounter any issues?"
          rules={[{ required: true, message: 'Please describe any issues' }]}
        >
          <TextArea rows={4} placeholder="Please describe any issues or bugs you encountered" />
        </Form.Item>
        <Form.Item
          name="improvements"
          label="What features would you like to see added or improved?"
        >
          <TextArea rows={4} placeholder="Your suggestions for improvements" />
        </Form.Item>
        <Form.Item className="form-buttons">
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit Feedback
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FeedbackForm;
