import React, { useState, useEffect } from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { Card } from 'antd';

interface SwipeCardProps {
  children: React.ReactNode;
  onSwipe: (direction: 'left' | 'right') => void;
  isVisible?: boolean;
}

export const SwipeCard: React.FC<SwipeCardProps> = ({ 
  children, 
  onSwipe,
  isVisible = true 
}) => {
  const [exitX, setExitX] = useState<number>(0);
  const x = useMotionValue(0);
  const rotate = useTransform(x, [-200, 200], [-25, 25]);
  const opacity = useTransform(x, [-200, -150, 0, 150, 200], [0, 1, 1, 1, 0]);

  const rightBgOpacity = useTransform(x, [0, 125], [0, 1]);
  const leftBgOpacity = useTransform(x, [-125, 0], [1, 0]);

  // Reset position when card changes
  useEffect(() => {
    setExitX(0);
    x.set(0);
  }, [children]);

  const dragEndHandler = (event: any, info: any) => {
    if (Math.abs(info.offset.x) > 100) {
      setExitX(info.offset.x);
      const direction = info.offset.x > 0 ? 'right' : 'left';
      onSwipe(direction);
    } else {
      // Reset position if not swiped far enough
      setExitX(0);
    }
  };

  if (!isVisible) return null;

  return (
    <motion.div
      className="swipeable-card"
      style={{ x, rotate, opacity }}
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      onDragEnd={dragEndHandler}
      animate={{ x: exitX }}
      whileDrag={{ cursor: 'grabbing' }}
      initial={{ x: 0 }}
    >
      <motion.div 
        className="swipe-indicator accept"
        style={{ opacity: rightBgOpacity }}
      />
      <motion.div 
        className="swipe-indicator reject"
        style={{ opacity: leftBgOpacity }}
      />
      <div className="request-card">
        {children}
      </div>
    </motion.div>
  );
}; 