import React, { useState, FormEvent } from 'react';
import { Button, Row, Col, DatePicker, Select, Input, Modal } from 'antd';
import { MinusCircleOutlined, PlusOutlined, CameraOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { addDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import './PickupRequestForm.css';
import 'antd/dist/antd.css';
import moment from 'moment';
import type { TimeSlot } from '../types/types';
import { Progress } from './Progress';
import logoGone from '../assets/gone.png';
import FeedbackForm from './FeedbackForm';
import { Autocomplete } from '@react-google-maps/api';
import TermsOfService from './TermsOfService';
import { formatPhoneNumber } from '../utils/phoneUtils';

const { Option } = Select;

const weekdaySlots = [
  { day: 'Monday', periods: ['AM', 'PM'] },
  { day: 'Tuesday', periods: ['AM', 'PM'] },
  { day: 'Wednesday', periods: ['AM', 'PM'] },
  { day: 'Thursday', periods: ['AM', 'PM'] },
  { day: 'Friday', periods: ['AM', 'PM'] }
];

const timeRangeSlots = {
  'AM': ['9:00 AM', '10:00 AM', '11:00 AM'],
  'PM': ['1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM']
};



interface Item {
  description: string;
  condition: string;
  selectedFiles: File[];
  fileUrls?: string[];
  status?: string;
}

interface PickupRequestFormProps {
  onBackToHome: () => void;
  handleLogout: () => void;
  onSubmitSuccess: () => void;
}

const PickupRequestForm: React.FC<PickupRequestFormProps> = ({ onBackToHome, handleLogout, onSubmitSuccess }) => {
  console.log('API Key:', process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  console.log('Firestore db instance:', db);
  console.log('Firebase collection reference:', collection(db, 'pickupRequests'));

  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [selectedDates, setSelectedDates] = useState<{ date: string, timeRange: string, times: string[] }[]>([]);
  const [currentDate, setCurrentDate] = useState<string | null>(null);
  const [selectedTimeRange, setSelectedTimeRange] = useState<string | null>(null);
  const [availableTimeSlots, setAvailableTimeSlots] = useState<string[]>([]);
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [items, setItems] = useState<Item[]>([{ description: '', condition: '', selectedFiles: [] }]);
  const [additionalNotes, setAdditionalNotes] = useState<string>('');
  const [formMessage, setFormMessage] = useState<string>('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);
  const [availableSlots, setAvailableSlots] = useState<TimeSlot[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [address, setAddress] = useState<string>('');
  const [unit, setUnit] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [ownershipConfirmed, setOwnershipConfirmed] = useState<boolean>(false);
  const [addressConfirmed, setAddressConfirmed] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [isTermsVisible, setIsTermsVisible] = useState(false);

  const steps = ['Contact', 'Photos', 'Details', 'Dates', 'Location'];

  const WelcomeSection = () => (
    <div className="welcome-section">
      <div className="welcome-content">
        <h3>Contact Information</h3>
        <div className="info-section">
          <p>To better serve you, we need:</p>
          <ul>
            <li>Your name</li>
            <li>Either an email address or mobile number</li>
          </ul>
          <p>This allows us to send service updates through your preferred contact method.</p>
        </div>
      </div>

      <div className="form-section">
        <div className="contact-form-group">
          <label>FULL NAME</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your full name"
            className="contact-input"
            required
          />
        </div>

        <div className="contact-form-group">
          <label>EMAIL ADDRESS OR PHONE NUMBER</label>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter email or phone number"
            className="contact-input"
            required
          />
          <span className="helper-text">We'll use this to send you updates about your pickup request</span>
        </div>
      </div>
    </div>
  );

  const handleFileChange = async (index: number, files: FileList) => {
    if (files.length > 0) {
      const newItems = [...items];
      newItems[index] = {
        ...newItems[index],
        selectedFiles: [files[0]]
      };
      setItems(newItems);
      
      // Only add new item if this is the last item and it now has a file
      if (index === items.length - 1) {
        setItems(prev => [...prev, { description: '', condition: '', selectedFiles: [] }]);
      }
    }
  };

  const handleDateChange = (date: any, dateString: string | string[]) => {
    setCurrentDate(Array.isArray(dateString) ? dateString[0] : dateString);
    setSelectedTimeRange(null);
    setAvailableTimeSlots([]);
    setSelectedTimes([]);
    if (dateString) {
      fetchAvailableSlots(Array.isArray(dateString) ? dateString[0] : dateString);
    }
  };

  const handleTimeRangeSelection = (range: 'AM' | 'PM') => {
    setSelectedTimeRange(range);
    setAvailableTimeSlots(timeRangeSlots[range]);
    setSelectedTimes([]);
  };

  const handleSpecificTimeSelection = (time: string) => {
    setSelectedTimes(prevTimes =>
      prevTimes.includes(time)
        ? prevTimes.filter(t => t !== time)
        : [...prevTimes, time]
    );
  };

  const addCurrentSelection = () => {
    if (!currentDate || !selectedTimeRange || selectedTimes.length === 0) return;

    setSelectedDates(prevDates => [
      ...prevDates,
      { date: currentDate, timeRange: selectedTimeRange, times: selectedTimes }
    ]);

    setCurrentDate(null);
    setSelectedTimeRange(null);
    setAvailableTimeSlots([]);
    setSelectedTimes([]);
  };

  const addItem = () => {
    setItems([...items, { description: '', condition: '', selectedFiles: [] }]);
  };

  const removeItem = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const removeFile = (itemIndex: number, fileIndex: number) => {
    const newItems = [...items];
    newItems[itemIndex].selectedFiles = newItems[itemIndex].selectedFiles.filter((_, idx) => idx !== fileIndex);
    setItems(newItems);
  };

  const handleItemChange = (index: number, field: keyof Item, value: any) => {
    const newItems = [...items];
    newItems[index][field] = value;
    setItems(newItems);
  };

  const handleGalleryClick = () => {
    if (selectedItemIndex !== null) {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = (e: any) => handleFileChange(selectedItemIndex, e.target.files);
      input.click();
    }
  };

  const fetchAvailableSlots = async (selectedDate: string) => {
    try {
      const response = await fetch(
        `/api/calendly-availability?date=${selectedDate}`,
        {
          headers: {
            'Authorization': `Bearer ${await getAuth().currentUser?.getIdToken()}`
          }
        }
      );

      const availableTimes = await response.json();
      
      // Convert Calendly times to your format
      const formattedTimes = availableTimes.map((slot: string) => {
        return moment(slot).format('h:mm A');
      });

      setAvailableTimeSlots(formattedTimes);
    } catch (error) {
      console.error('Error fetching Calendly slots:', error);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormMessage('');

    try {
      const formattedPhone = formatPhoneNumber(phoneNumber);
      
      // Process items with their images and details
      const processedItems = items
        .filter(item => item.selectedFiles.length > 0)
        .map(item => ({
          description: item.description,
          condition: item.condition,
          fileUrls: [],
          status: 'pending'
        }));

      // Upload files first
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.selectedFiles.length > 0) {
          const formData = new FormData();
          item.selectedFiles.forEach(file => {
            formData.append('files', file);
          });

          const uploadResponse = await fetch('/upload', {
            method: 'POST',
            body: formData
          });

          if (!uploadResponse.ok) {
            throw new Error('Failed to upload files');
          }

          const uploadData = await uploadResponse.json();
          processedItems[i].fileUrls = uploadData.fileUrls;
        }
      }

      // Create the Firestore document
      const pickupRequestData = {
        name,
        phoneNumber: formattedPhone,
        email: phoneNumber.includes('@') ? phoneNumber : '',
        createdAt: new Date().toISOString(),
        status: 'pending',
        items: processedItems,
        selectedTimes: selectedTimes,
        address: address,
        unit: unit,
        city: city,
        state: state,
        zipCode: zipCode
      };

      // Save to Firestore
      const docRef = await addDoc(collection(db, 'pickupRequests'), pickupRequestData);
      console.log('Document written with ID:', docRef.id);

      // Notify user
      try {
        await notifyUser(docRef.id, formattedPhone);
      } catch (error) {
        console.error('Failed to send notification:', error);
        // Don't fail the whole submission if notification fails
      }

      setFormMessage('Pickup request submitted successfully!');
      setIsSubmitted(true);
      setShowFeedback(true);

      onSubmitSuccess();
    } catch (error) {
      console.error('Submission error:', error);
      setFormMessage('Failed to submit request. Please try again.');
    }
  };

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
    setIsLoaded(true);
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      
      // Update address
      setAddress(place.formatted_address || '');
      
      // Extract and set other address components
      place.address_components?.forEach((component) => {
        const types = component.types;
        
        if (types.includes('locality')) {
          setCity(component.long_name);
        }
        if (types.includes('administrative_area_level_1')) {
          setState(component.short_name);
        }
        if (types.includes('postal_code')) {
          setZipCode(component.long_name);
        }
      });
    }
  };

  const handleTimeSelection = (timeSlot: string) => {
    setSelectedTimes(prevTimes => {
      if (prevTimes.includes(timeSlot)) {
        return prevTimes.filter(t => t !== timeSlot);
      }
      return [...prevTimes, timeSlot];
    });
  };

  const handleFeedbackClose = () => {
    setShowFeedback(false);
    setName('');
    setPhoneNumber('');
    setSelectedTimes([]);
    setItems([{ description: '', condition: '', selectedFiles: [] }]);
    setAddress('');
    setUnit('');
    setCity('');
    setState('');
    setZipCode('');
    setCurrentStep(1);
    setIsSubmitted(false);
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className="step-content">
            {/* Info Section */}
            <div className="info-section">
              <h3 className="section-title">Contact Information</h3>
              <p className="info-text">To better serve you, we need:</p>
              <ul>
                <li className="info-list-item">Your name</li>
                <li className="info-list-item">Either an email address or mobile number</li>
              </ul>
              <p className="info-text">This allows us to send service updates through your preferred contact method.</p>
            </div>

            {/* Form Section - Separated from info */}
            <div className="form-section">
              <div className="contact-form-group">
                <label className="form-label">FULL NAME</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your full name"
                  className="contact-input"
                />
              </div>

              <div className="contact-form-group">
                <label className="form-label">PHONE NUMBER OR EMAIL ADDRESS</label>
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter email or phone number"
                  className="contact-input"
                />
                <span className="helper-text">Choose the contact method you check most frequently for fastest updates.</span>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="step-content">
            <h3 className="section-title">Upload Photos (one per item)</h3>
            <div 
              className="upload-section" 
              onClick={() => {
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*';
                input.onchange = (e: any) => handleFileChange(items.length - 1, e.target.files);
                input.click();
              }}
            >
              <div className="upload-icon">
                <PlusOutlined />
              </div>
              <p>Drop photos here or click to upload</p>
              <p className="info-text">Upload clear photos of each item you'd like us to pick up</p>
            </div>

            <div className="uploaded-photos-grid">
              {items.map((item, index) => (
                item.selectedFiles[0] && (
                  <div key={index} className="photo-item">
                    <div className="photo-container">
                      <img 
                        src={URL.createObjectURL(item.selectedFiles[0])} 
                        alt={`Item ${index + 1}`} 
                      />
                      <div className="remove-button-wrapper">
                        <Button
                          className="remove-button"
                          icon={<MinusCircleOutlined />}
                          onClick={() => removeItem(index)}
                        />
                      </div>
                    </div>
                    <div className="photo-label">Item {index + 1}</div>
                  </div>
                )
              ))}
            </div>
          </div>
        );
      case 3:
        return (
          <div className="step-content">
            <h3 className="section-title">Item Details</h3>
            {items.filter(item => item.selectedFiles.length > 0).map((item, index) => (
              <div key={index} className="item-detail-row">
                <div className="item-photo">
                  <img src={URL.createObjectURL(item.selectedFiles[0])} alt={`Item ${index + 1}`} />
                  <div className="remove-button-wrapper">
                    <Button
                      className="remove-button"
                      icon={<MinusCircleOutlined />}
                      onClick={() => removeItem(index)}
                    />
                  </div>
                </div>
                <div className="item-inputs">
                  <input
                    type="text"
                    placeholder="Item description"
                    value={item.description}
                    onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                    required
                  />
                  <input
                    type="text"
                    placeholder="Item condition"
                    value={item.condition}
                    onChange={(e) => handleItemChange(index, 'condition', e.target.value)}
                    required
                  />
                </div>
              </div>
            ))}
          </div>
        );
      case 4:
        return (
          <div className="step-content">
            <h3 className="section-title">Pickup Date</h3>
            
           
        
            <div className="time-slots-container">
              {weekdaySlots.map(({ day, periods }) => (
                periods.map(period => (
                  <button
                    key={`${day}-${period}`}
                    className={`time-slot-button ${selectedTimes.includes(`${day} ${period}`) ? 'selected' : ''}`}
                    onClick={() => handleTimeSelection(`${day} ${period}`)}
                    type="button"
                  >
                    {day} {period}
                  </button>
                ))
              ))}
            </div>
            

            
          </div>
        );
      case 5:
        return (
          <div className="step-content">
            <h2 className="section-title">Location</h2>
            
            <div className="form-group">
              <label className="form-label">PICKUP ADDRESS</label>
              <Autocomplete
                onLoad={onLoad}
                onPlaceChanged={onPlaceChanged}
              >
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  placeholder="Enter your complete address"
                  className="location-input"
                  required
                />
              </Autocomplete>
              <p className="helper-text">
                Please ensure the address is accurate and items will be accessible at this location
              </p>
            </div>

            <div className="final-confirmation">
              <h3 className="section-title">Final Confirmation</h3>
              <div className="checkbox-group">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={ownershipConfirmed}
                    onChange={(e) => setOwnershipConfirmed(e.target.checked)}
                  />
                  I confirm I own this item or have permission to request service for it
                </label>
                
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={addressConfirmed}
                    onChange={(e) => setAddressConfirmed(e.target.checked)}
                  />
                  I confirm the address provided is correct and I can receive services there
                </label>
                
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                  />
                  I accept the <a href="#" onClick={(e) => {
                    e.preventDefault();
                    setIsTermsVisible(true);
                  }}>Terms of Service</a>
                </label>
              </div>
            </div>
          </div>
        );
    }
  };

  // Helper function to notify user
  const notifyUser = async (requestId: string, formattedPhone: string) => {
    try {
      await axios.post(
        'https://gone-backend-990800642935.us-west1.run.app/notify-user',
        {
          phoneNumber: formattedPhone,
          email: phoneNumber.includes('@') ? phoneNumber : '',
          name,
          requestId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
    } catch (error) {
      console.error('Failed to send user notification:', error);
      throw error;
    }
  };

  return (
    <div>
      <form className="pickup-form" onSubmit={handleSubmit}>
        <Progress steps={steps} currentStep={currentStep} />
        
        {renderStepContent()}
  
        <div className="form-navigation">
          {currentStep > 1 && (
            <Button 
              className="previous-button"
              onClick={() => setCurrentStep(prev => prev - 1)}
            >
              Previous
            </Button>
          )}
          
          <Button 
            type="primary"
            className="next-button"
            disabled={
              (currentStep === 1 && (!phoneNumber.trim())) ||
              (currentStep === 2 && !items.some(item => item.selectedFiles.length > 0)) ||
              (currentStep === 4 && selectedTimes.length === 0) ||
              (currentStep === 5 && (!ownershipConfirmed || !addressConfirmed || !termsAccepted))
            }
            onClick={() => {
              if (currentStep === 5) {
                const fakeEvent = { preventDefault: () => {} } as FormEvent<HTMLFormElement>;
                handleSubmit(fakeEvent);
              } else {
                setCurrentStep(prev => prev + 1);
              }
            }}
          >
            {currentStep === 5 ? 'Submit' : 'Next'}
          </Button>
        </div>
  
        {formMessage && <p className="status-message">{formMessage}</p>}
      </form>

      {showFeedback && (
        <Modal
          visible={showFeedback}
          onCancel={handleFeedbackClose}
          footer={null}
          width={700}
        >
          <FeedbackForm onClose={handleFeedbackClose} />
        </Modal>
      )}

      <TermsOfService 
        isVisible={isTermsVisible}
        onClose={() => setIsTermsVisible(false)}
      />
    </div>
  );
  
};

export default PickupRequestForm;