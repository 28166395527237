import React, { useState } from 'react';
import { Tabs } from 'antd';
import './Adminmain.css';
import AdminDashboard from './AdminDashboard';

const { TabPane } = Tabs;

interface AdminDashboardMainProps {
  onNavigate: (view: 'accepted') => void;
  handleLogout: () => void;
}

const AdminDashboardMain: React.FC<AdminDashboardMainProps> = ({ 
  onNavigate, 
  handleLogout 
}) => {
  const [activeTab, setActiveTab] = useState('pending');

  return (
    <div className="dashboard-main">
      <div className="dashboard-header">
        <Tabs 
          activeKey={activeTab} 
          onChange={setActiveTab}
          className="dashboard-tabs"
        >
          <TabPane tab="Pending Requests" key="pending">
            <AdminDashboard 
              statusFilter="pending" 
              onBackToHome={handleLogout}
              showActions={true}
            />
          </TabPane>
          <TabPane tab="Accepted Requests" key="accepted">
            <AdminDashboard 
              statusFilter="accepted" 
              onBackToHome={handleLogout}
              showActions={true}
            />
          </TabPane>
          <TabPane tab="All Requests" key="all">
            <AdminDashboard 
              onBackToHome={handleLogout}
              showActions={true}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default AdminDashboardMain;