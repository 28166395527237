import React, { useState, useEffect } from 'react';
import { Button, Spin, Input, Form, message, Modal, BackTop } from 'antd';
import { getAuth, signInWithPopup, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, User } from 'firebase/auth';
import AdminDashboard from './components/AdminDashboard';
import PickupRequestForm from './components/PickupRequestForm';
import AdminDashboardMain from './components/Adminmain';
import FeedbackForm from './components/FeedbackForm';
import SubmissionSuccess from './components/SubmissionSuccess';
import { CommentOutlined } from '@ant-design/icons';

import './App.css';
import logo1 from './assets/gone.png'; 
import logo2 from './assets/tree.png'; 

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [adminView, setAdminView] = useState<'dashboard' | 'all' | 'pending' | 'accepted'>('dashboard');
  const [userView, setUserView] = useState<'home' | 'pickupForm' | 'pickupStatus'>('home'); 
  const [showFeedback, setShowFeedback] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const auth = getAuth();

  useEffect(() => {
    document.title = 'gone.com';  
  }, []);

  const handleGoogleLogin = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    
    provider.setCustomParameters({
      prompt: 'select_account',
      redirect_uri: process.env.REACT_APP_REDIRECT_URL || 'https://app.gone.com'
    });

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      setUser(user);
      setIsLoggedIn(true);

      const normalizedEmail = user.email?.trim().toLowerCase() || '';
      setIsAdmin(normalizedEmail.endsWith('@itsgonenow.com'));
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      message.error('Failed to sign in with Google.');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailPasswordLogin = async (values: any) => {
    setLoading(true);
    try {
      const { email, password } = values;
      const result = await signInWithEmailAndPassword(auth, email, password);
      setUser(result.user);
      setIsLoggedIn(true);

      const normalizedEmail = result.user.email?.trim().toLowerCase() || '';
      setIsAdmin(normalizedEmail.endsWith('@itsgonenow.com'));
    } catch (error: any) {
      if (error.code === 'auth/wrong-password') {
        message.error('Incorrect password. Please try again.');
      } else if (error.code === 'auth/user-not-found') {
        message.error('No account found with this email. Please sign up.');
      } else {
        console.error('Email Sign-In Error:', error);
        message.error(`Failed to sign in: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSignup = async (values: any) => {
    setLoading(true);
    try {
      const { firstName, lastName, email, password } = values;

      if (password.length < 6) {
        message.error('Password should be at least 6 characters long.');
        setLoading(false);
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);

      if (userCredential.user) {
        message.success('Account created successfully. Please log in.');
      }

      setShowLogin(true);
    } catch (error: any) {
      if (error.code === 'auth/email-already-in-use') {
        message.error('This email is already registered. Please log in instead.');
      } else {
        console.error('Signup Error:', error);
        message.error(`Failed to create an account. Error: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    setLoading(true);
    try {
      await auth.signOut();
      setUser(null);
      setIsLoggedIn(false);
      setIsAdmin(false);
    } catch (error) {
      console.error('Logout Error:', error);
      message.error('Failed to log out.');
    } finally {
      setLoading(false);
    }
  };

  const handleFormSubmitSuccess = () => {
    setShowSuccess(true);
  };

  const handleBackToHome = () => {
    setShowSuccess(false);
    setShowForm(false);
    setUserView('home');
    setShowFeedback(false);
    // Reset any other necessary state
  };

  const splashScreen = (
    <div className="centered-container">
      <div className="logo-container">
        <img src={logo2} alt="Tree Logo" className="tree-logo" />
        <img src={logo1} alt="Gone Logo" className="gone-logo" />
      </div>
    </div>
  );

  const loginForm = (
    <Form onFinish={handleEmailPasswordLogin}>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: 'Please enter your email' },
          { type: 'email', message: 'The input is not valid E-mail!' }
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          { required: true, message: 'Please enter your password' },
          { min: 6, message: 'Password must be at least 6 characters' }
        ]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      
      <Button type="primary" htmlType="submit" block loading={loading}>
        Sign In
      </Button>
      <Button type="default" block onClick={() => setShowLogin(false)}>
        Create an Account
      </Button>
      <Button type="default" block onClick={handleGoogleLogin}>
        Sign In with Google
      </Button>
    </Form>
  );

  const signupForm = (
    <Form onFinish={handleSignup}>
      <Form.Item name="firstName" rules={[{ required: true, message: 'Please enter your first name' }]}>
        <Input placeholder="First Name" />
      </Form.Item>
      <Form.Item name="lastName" rules={[{ required: true, message: 'Please enter your last name' }]}>
        <Input placeholder="Last Name" />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: 'Please enter your email' },
          { type: 'email', message: 'The input is not valid E-mail!' }
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: 'Please enter your password' },
          { min: 6, message: 'Password must be at least 6 characters' }
        ]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        rules={[
          { required: true, message: 'Please confirm your password' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords do not match!'));
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirm Password" />
      </Form.Item>

      <Button type="primary" htmlType="submit" block loading={loading}>
        Sign Up
      </Button>
      <Button type="default" block onClick={() => setShowLogin(true)}>
        Back to Login
      </Button>
      <Button type="default" block onClick={handleGoogleLogin}>
        Sign Up with Google
      </Button>
    </Form>
  );

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <div className="App">
      <header className="header">
        <img src={logo1} alt="Gone Logo" className="header-logo" />
        {isLoggedIn ? (
          <Button 
            onClick={handleLogout}
            className="home-button"
          >
            Logout
          </Button>
        ) : showForm ? (
          <Button 
            onClick={() => {
              setShowForm(false);
              setUserView('home');
              setAdminView('dashboard');
            }} 
            className="home-button"
          >
            Home
          </Button>
        ) : (
          <Button 
            onClick={() => setShowForm(true)} 
            className="home-button"
          >
            Login
          </Button>
        )}
      </header>

      {showForm && !isLoggedIn ? (
        // Login/Signup Form
        <div className="centered-container">
          <div className="logo-container">
            <img src={logo2} alt="Tree Logo" className="tree-logo" />
            <img src={logo1} alt="Gone Logo" className="gone-logo" />
          </div>
          {showLogin ? loginForm : signupForm}
        </div>
      ) : isLoggedIn && isAdmin ? (
        // Admin Dashboard
        adminView === 'dashboard' ? (
          <AdminDashboardMain 
            onNavigate={(view) => setAdminView(view)} 
            handleLogout={handleLogout}
          />
        ) : (
          <AdminDashboard 
            statusFilter={adminView === 'all' ? undefined : adminView}
            onBackToHome={handleLogout}
          />
        )
      ) : showSuccess ? (
        <SubmissionSuccess 
          onBackToHome={handleBackToHome}
          onFeedback={() => setShowFeedback(true)}
        />
      ) : (
        <PickupRequestForm
          onBackToHome={() => setUserView('home')}
          handleLogout={handleLogout}
          onSubmitSuccess={handleFormSubmitSuccess}
        />
      )}

      <div className="bottom-bar">
        © 2024 gone.com Component Library. All rights reserved.
      </div>

      <BackTop>
        <Button
          icon={<CommentOutlined />}
          onClick={() => setShowFeedback(true)}
          type="primary"
          style={{ 
            right: 24,
            bottom: 24,
            backgroundColor: '#4B7163' 
          }}
        />
      </BackTop>

      <Modal
        visible={showFeedback}
        onCancel={() => setShowFeedback(false)}
        footer={null}
        width={700}
      >
        <FeedbackForm onClose={() => setShowFeedback(false)} />
      </Modal>
    </div>
  );
};

export default App;
