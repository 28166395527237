import React, { useState, useEffect } from 'react';
import { Button, Table, Card } from 'antd';
import { getDocs, updateDoc, collection, doc, query, where, onSnapshot } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { getAuth } from 'firebase/auth';
import './AdminDashboard.css';
import { SwipeCard } from './SwipeCard';
import MessageModal from './MessageModal';
import { formatPhoneNumber } from '../utils/phoneUtils';

interface PickupRequest {
  id: string;
  name: string;
  phoneNumber: string;
  email?: string;
  createdAt: string;
  status: string;
  address: string;
  unit?: string;
  city: string;
  state: string;
  zipCode: string;
  selectedTimes: string[];
  items: {
    description: string;
    condition: string;
    fileUrls?: string[];
    status?: string;
  }[];
  messages?: {
    text: string;
    timestamp: any;
    sender: 'user' | 'system' | 'admin';
    type?: 'confirmation';
  }[];
}

interface AdminDashboardProps {
  statusFilter?: string;
  onBackToHome: () => void;
  showActions?: boolean;
}

const AdminDashboard: React.FC<AdminDashboardProps> = ({ 
  statusFilter, 
  onBackToHome,
  showActions = false 
}) => {
  const [requests, setRequests] = useState<PickupRequest[]>([]);
  const [currentRequestIndex, setCurrentRequestIndex] = useState<number>(0);
  const [message, setMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [pendingRequests, setPendingRequests] = useState<PickupRequest[]>([]);
  const [direction, setDirection] = useState<'left' | 'right' | null>(null);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [messages, setMessages] = useState<any[]>([]);
  const [isMessageModalVisible, setIsMessageModalVisible] = useState(false);
  const [currentRequest, setCurrentRequest] = useState<PickupRequest | null>(null);

  useEffect(() => {
    const fetchRequests = async () => {
      setLoading(true);

      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        setMessage('User is not authenticated.');
        setLoading(false);
        return;
      }

      const baseQuery = collection(db, 'pickupRequests');
      const unsubscribe = onSnapshot(baseQuery, (snapshot) => {
        const requestData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as PickupRequest[];
        
        if (statusFilter === 'pending') {
          // Filter requests that have at least one pending item
          const pendingRequests = requestData.map(request => ({
            ...request,
            items: request.items.filter(item => !item.status || item.status === 'pending')
          })).filter(request => request.items.length > 0);
          setPendingRequests(pendingRequests);
        } else if (statusFilter === 'accepted') {
          // Filter requests and only include accepted items
          const acceptedRequests = requestData.map(request => ({
            ...request,
            items: request.items.filter(item => item.status === 'accepted')
          })).filter(request => request.items.length > 0);
          setRequests(acceptedRequests);
        } else {
          // Show all requests with all items
          setRequests(requestData);
        }
        setLoading(false);
      });

      return () => unsubscribe();
    };

    fetchRequests();
  }, [statusFilter]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 5000); // 5 seconds

      return () => clearTimeout(timer);
    }
  }, [message]);

  useEffect(() => {
    // Only set up listener if there's a current request
    if (currentRequest?.id) {
      const unsubscribe = onSnapshot(
        doc(db, 'pickupRequests', currentRequest.id),
        (doc) => {
          const data = doc.data();
          if (data?.messages) {
            setMessages(data.messages);
          }
        }
      );

      return () => unsubscribe();
    }
  }, [currentRequest?.id]);

  const handleSwipe = async (direction: 'left' | 'right') => {
    const currentRequest = pendingRequests[currentIndex];
    const currentItem = currentRequest.items[currentItemIndex];

    try {
      const requestRef = doc(db, 'pickupRequests', currentRequest.id);
      const updatedItems = [...currentRequest.items];
      updatedItems[currentItemIndex] = {
        ...currentItem,
        status: direction === 'right' ? 'accepted' : 'rejected'
      };

      await updateDoc(requestRef, {
        items: updatedItems
      });

      // Remove the current request from pendingRequests if all items have been processed
      if (currentItemIndex === currentRequest.items.length - 1) {
        setPendingRequests(prev => prev.filter(req => req.id !== currentRequest.id));
      } else {
        setCurrentItemIndex(prev => prev + 1);
      }

      setMessage(`Item ${direction === 'right' ? 'accepted' : 'rejected'}`);
    } catch (error) {
      console.error('Error updating item status:', error);
      setMessage('Failed to update item status');
    }
  };

  const renderPendingRequests = () => {
    if (pendingRequests.length === 0) {
      return <div className="no-requests"><p>No pending requests found</p></div>;
    }

    const currentRequest = pendingRequests[currentIndex];
    
    // Add safety check for currentRequest
    if (!currentRequest) {
      return <div className="no-requests"><p>No request data available</p></div>;
    }

    const currentItem = currentRequest.items[currentItemIndex];

    const handleMessageClick = (request: PickupRequest) => {
      setCurrentRequest(request);
      setIsMessageModalVisible(true);
    };

    return (
      <div className="pickup-management">
        <h2 className="management-title">Pickup Request Management</h2>
        
        <div className="navigation-controls">
          <span className="page-count">
            Request {currentIndex + 1} of {pendingRequests.length} | 
            Item {currentItemIndex + 1} of {currentRequest.items?.length || 0}
          </span>
        </div>

        <div className="review-section">
          <div className="card-container">
            <SwipeCard
              onSwipe={handleSwipe}
              isVisible={currentItemIndex < (currentRequest.items?.length || 0)}
            >
              <div className="item-content">
                {currentItem?.fileUrls?.[0] && (
                  <img 
                    src={currentItem.fileUrls[0]} 
                    alt={currentItem.description || ''}
                    className="item-image"
                  />
                )}
                
                <div className="item-details">
                  <p className="item-description">{currentItem?.description || ''}</p>
                  <p className="item-condition">{currentItem?.condition || ''}</p>
                </div>

                <div className="request-info">
                  <div className="location-info">
                    <span className="location-icon">📍</span>
                    <span className="location-text">
                      {currentRequest.address || ''}
                      {currentRequest.unit && `, Unit ${currentRequest.unit}`}
                    </span>
                  </div>

                  <div className="time-slots">
                    <span className="time-icon">🕒</span>
                    <span>Available: {Array.isArray(currentRequest.selectedTimes) ? 
                      currentRequest.selectedTimes.join(', ') : 
                      'No times available'}</span>
                  </div>
                </div>
              </div>
            </SwipeCard>
          </div>
        </div>

        <div className="swipe-instructions">
          <span>✕ Swipe left to reject this item</span>
          <span>✓ Swipe right to accept this item</span>
        </div>

        <div className="messages-section">
          <button 
            className="messages-button"
            onClick={() => handleMessageClick(currentRequest)}
          >
            💬 Messages {currentRequest.messages?.length ? `(${currentRequest.messages.length})` : '(0)'}
          </button>
        </div>

        {currentRequest && (
          <MessageModal
            visible={isMessageModalVisible}
            onClose={() => setIsMessageModalVisible(false)}
            messages={currentRequest.messages || []}
            phoneNumber={currentRequest.phoneNumber}
            requestId={currentRequest?.id}
          />
        
        )}
      </div>
    );
  };

  const renderRegularRequests = () => (
    <div className="requests-container">
      {requests.length === 0 ? (
        <div className="no-requests">
          <p>No {statusFilter || ''} requests found</p>
        </div>
      ) : (
        requests.map((request) => (
          <Card key={request.id} className="request-card">
            <div className="request-details">
              <p><strong>Name:</strong> {request.name || 'N/A'}</p>
              <p><strong>Email:</strong> {request.email || 'N/A'}</p>
              <p><strong>Phone:</strong> {request.phoneNumber || 'N/A'}</p>
              <p><strong>Available Times:</strong> {Array.isArray(request.selectedTimes) ? 
                request.selectedTimes.join(', ') : 'No times available'}</p>
              <p><strong>Status:</strong> {statusFilter || request.status || 'N/A'}</p>
              <p><strong>Address:</strong> {request.address || 'N/A'}
                {request.unit ? `, Unit ${request.unit}` : ''}</p>
              
              {/* Items Section */}
              {Array.isArray(request.items) && request.items.map((item, index) => (
                <div key={index} className="item-section">
                  <p><strong>Item {index + 1} Description:</strong> {item?.description || 'N/A'}</p>
                  <p><strong>Condition:</strong> {item?.condition || 'N/A'}</p>
                  <p><strong>Status:</strong> {item?.status || 'pending'}</p>
                  {item?.fileUrls && item.fileUrls.length > 0 && (
                    <p>
                      <a href={item.fileUrls[0]} target="_blank" rel="noopener noreferrer">
                        View Photo
                      </a>
                    </p>
                  )}
                </div>
              ))}
            </div>
          </Card>
        ))
      )}
    </div>
  );

  const renderRequest = (request: any) => {
    const currentItem = request.items[currentItemIndex];
    
    return (
      <div className="request-card">
        {/* Image Section */}
        <div className="item-image">
          {currentItem?.fileUrls?.[0] && (
            <img 
              src={currentItem.fileUrls[0]} 
              alt={currentItem.description} 
              className="item-preview"
            />
          )}
        </div>

        {/* Item Details */}
        <div className="item-details">
          <h3>Item {currentItemIndex + 1} of {request.items.length}</h3>
          <p className="item-description">{currentItem?.description}</p>
          <p className="item-condition">Condition:{currentItem?.condition}</p>
        </div>

        {/* Location */}
        <div className="location-info">
          <span className="location-icon">📍</span>
          {request.address}
          {request.unit && `, Unit ${request.unit}`}
          {`, ${request.city}, ${request.state} ${request.zipCode}`}
        </div>

        {/* Available Times */}
        <div className="time-slots">
          <span className="time-icon">🕒</span>
          <span>Available:</span>
          {request.selectedTimes.join(', ')}
        </div>

        {/* Navigation Arrows */}
        <div className="item-navigation">
          {currentItemIndex > 0 && (
            <button 
              onClick={() => setCurrentItemIndex(prev => prev - 1)}
              className="nav-button prev"
            >
              ←
            </button>
          )}
          {currentItemIndex < request.items.length - 1 && (
            <button 
              onClick={() => setCurrentItemIndex(prev => prev + 1)}
              className="nav-button next"
            >
              →
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="admin-dashboard">
      {loading ? (
        <div>Loading...</div>
      ) : statusFilter === 'pending' ? (
        renderPendingRequests()
      ) : (
        renderRegularRequests()
      )}

      {message && <p className="status-message">{message}</p>}
    </div>
  );
};

export default AdminDashboard;