// src/firebaseConfig.ts
import { initializeApp } from "firebase/app";
import { getFirestore, Firestore } from "firebase/firestore";
import { getAuth, Auth } from "firebase/auth";
import { getStorage, FirebaseStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDEtOjh0x7nTwlFdJaQAvgg1_1dpKfiz9g",
  authDomain: "pickup-436017.firebaseapp.com",
  projectId: "pickup-436017",
  storageBucket: "pickup-436017.appspot.com",
  appId: "1:990800642935:web:9e965fd4d9c9cf50505772",
  measurementId: "G-TGRV39M2JC"
};

const app = initializeApp(firebaseConfig);

export const auth: Auth = getAuth(app);
export const db: Firestore = getFirestore(app);
export const storage: FirebaseStorage = getStorage(app);

