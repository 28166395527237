import React, { useState } from 'react';
import { Modal, Input, Button } from 'antd';
import './MessageModal.css';

interface Message {
  text: string;
  timestamp: any;
  sender: 'user' | 'system' | 'admin';
  type?: 'confirmation';
}

interface MessageModalProps {
  visible: boolean;
  onClose: () => void;
  messages: Message[];
  phoneNumber: string;
  requestId: string; // Add request ID to associate with Firestore update
}

const MessageModal: React.FC<MessageModalProps> = ({
  visible,
  onClose,
  messages,
  phoneNumber,
  requestId
}) => {
  const [newMessage, setNewMessage] = useState('');

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      // Call API to store the message in Firestore and optionally notify the user
      await fetch(`/admin/send-message`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          requestId,
          message: newMessage,
          phoneNumber
        }),
      });
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  return (
    <Modal
      title={`Messages - Phone Number: ${phoneNumber}`}
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={500}
    >
      <div className="messages-container">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender}-message`}>
            <div className="message-content">
              <p>{message.text}</p>
              <span className="message-time">{message.timestamp?.toDate().toLocaleString()}</span>
            </div>
          </div>
        ))}
      </div>
      <Input.TextArea
        rows={2}
        placeholder="Type a message"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
      />
      <Button type="primary" onClick={handleSendMessage} disabled={!newMessage.trim()}>
        Send
      </Button>
    </Modal>
  );
};

export default MessageModal;
