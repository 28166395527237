import React from 'react';
import { Button } from 'antd';
import './SubmissionSuccess.css';

interface SubmissionSuccessProps {
  onBackToHome: () => void;
  onFeedback: () => void;
}

const SubmissionSuccess: React.FC<SubmissionSuccessProps> = ({ onBackToHome, onFeedback }) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 'calc(100vh - 200px)' // Adjust for header and footer
    }}>
      <h1>Pickup Request Submitted Successfully!</h1>
      <p>Thank you for your submission. We'll review your request and get back to you soon.</p>
      <div style={{ display: 'flex', gap: '16px' }}>
        <Button onClick={onFeedback}>Submit Feedback</Button>
        <Button onClick={onBackToHome}>Back to Home</Button>
      </div>
    </div>
  );
};

export default SubmissionSuccess; 